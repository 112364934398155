<template>
  <div>
    <v-card elevation="4">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-h5 font-weight-medium">Duração</div>
        <HelpDialog
          class="mx-4 flex-grow-1"
          title="Duração do Tratamento de Dados"
          message="Preciso de ajuda para a duração do tratamento de dados."
        >
          <p>
            A LGPD descreve, de maneira subjetiva, que o Titular deve estar ciente da duração que terá o tratamento de seus dados.
            Isso envolve, também, por quanto tempo o consentimento é valido e o Controlador pode armazenar os dados do Titular.
          </p>
          <v-expansion-panels class="my-2" ripple>
            <v-expansion-panel>
              <v-expansion-panel-header color="grey lighten-2">
                Acesso fácil à Duração do Tratamento de Dados (LGPD Art. 9, inciso II)
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4">
                <p>...</p>
                <p>
                  <b>Art. 9º:</b> O titular tem direito ao acesso facilitado às informações sobre o tratamento de seus dados, que deverão ser disponibilizadas de forma clara, adequada e ostensiva acerca de, entre outras características previstas em regulamentação para o atendimento do princípio do livre acesso:
                </p>
                <p>...</p>
                <p>
                  <b>II</b> - forma e duração do tratamento, observados os segredos comercial e industrial;
                </p>
                <p>...</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </HelpDialog>
        <v-btn color="success" @click="openDurationDialog()">
          Nova Duração
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-subtitle class="py-2">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details="auto"
          class="mt-0 pt-0"
        />
      </v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="durations"
        :items-per-page="-1"
        :search="search"
        :loading="loading"
        loading-text="Carregando... Por favor, espere"
        hide-default-footer
        no-data-text="Nenhuma Duração cadastrada"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="openDurationDialog(item)">
            <v-icon class="mr-2"> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            icon
            @click="
              deleteDialog = true;
              duration_id = item.id;
            "
          >
            <v-icon class="mr-2"> mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-divider />
    </v-card>

    <v-dialog
      v-model="durationDialog"
      max-width="800"
      :persistent="loadingSave"
    >
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between">
          <div v-if="durationForm.id">Edição de Duração</div>
          <div v-else>Nova Duração</div>
          <div>
            <v-btn icon @click="durationDialog = false" :disabled="loadingSave">
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider />
        <v-form ref="form" @submit.prevent="saveDuration()">
          <v-card-text class="pa-6">
            <v-row no-gutters>
              <v-col>
                <v-text-field
                  v-model.number="durationForm.days"
                  type="number"
                  hide-details="auto"
                  label="Dias"
                  outlined
                  :disabled="loadingSave"
                  :rules="[$rules.positive]"
                  @keypress="isNumber($event)"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="durationForm.weeks"
                  class="ml-2"
                  hide-details="auto"
                  label="Semanas"
                  outlined
                  :disabled="loadingSave"
                  :rules="[$rules.positive]"
                  @keypress="isNumber($event)"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="durationForm.months"
                  class="ml-2"
                  hide-details="auto"
                  label="Meses"
                  outlined
                  :disabled="loadingSave"
                  :rules="[$rules.positive]"
                  @keypress="isNumber($event)"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="durationForm.years"
                  class="ml-2"
                  hide-details="auto"
                  label="Ano(s)"
                  outlined
                  :disabled="loadingSave"
                  :rules="[$rules.positive]"
                  @keypress="isNumber($event)"
                />
              </v-col>
            </v-row>
            <div class="text-subtitle-2 font-weight-regular text-center mt-6">
              * Somente um dos campos deve ser preenchido
            </div>
          </v-card-text>
          <v-card-actions class="pb-5">
            <v-spacer />
            <v-btn
              color="secondary"
              class="mr-1"
              text
              @click="durationDialog = false"
              :disabled="loadingSave"
            >
              Cancelar
            </v-btn>
            <v-btn color="success" class="mr-3" type="submit" :loading="loadingSave">
              <template v-if="durationForm.id">
                Salvar
              </template>
              <template v-else>
                Criar
              </template>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="500"
      :persistent="loadingDelete"
    >
      <v-card color="grey lighten-2">
        <v-card-title class="text-subtitle-1 d-flex justify-center">
          Tem certeza que deseja excluir essa Duração?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            class="mr-1"
            text
            :disabled="loadingDelete"
            @click="deleteDialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="ml-1"
            :loading="loadingDelete"
            color="error"
            text
            @click="deleteDuration()"
          >
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HelpDialog from "@/components/HelpDialog.vue";

export default {
  name: "Duration",

  components: { HelpDialog, },

  data: () => ({
    search: "",
    loading: false,
    loadingSave: false,
    loadingDelete: false,
    durationDialog: false,
    deleteDialog: false,
    duration_id: -1,
    durationForm: {
      days: 0,
      weeks: 0,
      months: 0,
      years: 0,
    },
    headers: [
      {
        text: "Titulo",
        align: "start",
        sortable: true,
        //filtereable: false,
        value: "name",
      },
      
      {
        text: "",
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
    durations: [],
  }),
  mounted() {
    this.getDurations();
  },
  methods: {
    isNumber(e){
      e = e || window.event
      var charCode = e.which || evt.keyCode
      if (!(charCode <= 31 || (48 <= charCode && charCode <= 57))) {
        e.preventDefault()
      } else {
        return true
      }
    },
    saveDuration() {
      let filled = !!this.durationForm.days +
                   !!this.durationForm.weeks + 
                   !!this.durationForm.months +
                   !!this.durationForm.years
      if (filled == 0){
        this.$showMessage('warning', "Preencha um dos períodos de duração")
      } else if (filled > 1){
        this.$showMessage('warning', "Preencha somente uma duração")
      } else if (this.$refs.form.validate()) {
        this.loadingSave = true;
        let request;
        let durationForm = this.durationForm
        durationForm.days = durationForm.days || undefined
        durationForm.weeks = durationForm.weeks || undefined
        durationForm.months = durationForm.months || undefined
        durationForm.years = durationForm.years || undefined
        if (this.durationForm.id) {
          request = this.$axios.put(`/duration/${this.durationForm.id}`, this.durationForm);
        } else {
          request = this.$axios.post("/duration", this.durationForm);
        }
        request
          .then((response) => {
            this.$showMessage("success", "Duração Salva");
            this.getDurations();
            this.durationDialog = false;
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 404) {
                this.$showMessage("error", "Duração não encontrada");
                return;
              }
            }
            this.$showMessage("error", "Ocorreu um erro inesperado");
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },
    deleteDuration() {
      this.loadingDelete = true;
      this.$axios
        .delete(`/duration/${this.duration_id}`)
        .then((response) => {
          this.$showMessage("success", "Duração Excluida");
          this.getDurations();
          this.deleteDialog = false;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 404) {
              this.$showMessage("error", "Duração não encontrada");
              return;
            }
          }
          this.$showMessage("error", "Ocorreu um erro inesperado");
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },
    getDurations() {
      this.loading = true;
      this.$axios
        .get("/durations")
        .then((response) => {
          this.durations = response.data;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 422) {
              this.$showMessage(
                "error",
                "Não foi possível carregar as Durações"
              );
            }
          }
          this.$showMessage(
            "error",
            "Erro inesperado no carregamento das Durações"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /* resetForm(){
      this.$refs.form.reset()
    }, */
    openDurationDialog(duration) {
      if (duration) {
        this.durationForm = Object.assign({}, duration);
      } else {
        this.durationForm = {};
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      }
      this.durationDialog = true;
    },
  },
};
</script>
